import { yupResolver } from '@hookform/resolvers/yup';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { object, string } from 'yup';
import { ContactFormStyle } from '../../../styles/components/common/forms';
import Message from '../ui/Message';

const DynamicButton = dynamic(() => import('../ui/Button'));
const DynamicInput = dynamic(() => import('../ui/Input'));
const DynamicTextarea = dynamic(() => import('../ui/Textarea'));

export interface IContactFormValues {
  name: string;
  email: string;
  message: string;
}

export interface IContactFormProps {
  formSubmit: (formData: IContactFormValues) => void;
  setIsButtonDisabled: (isButtonDisabled: boolean) => void;
  isButtonDisabled: boolean;
  darkMode?: boolean;
  labels: {
    name: string;
    email: string;
    message: string;
    submit: string;
    submitSuccessMessage: string;
    submitErrorMessage: string;
  };
  submitResultType: 'success' | 'error' | undefined;
}

const validationSchema = object().shape({
  name: string().trim().required('Name is required'),
  email: string().email('Email is invalid').required('Email is required'),
  message: string().trim().required('Message is required'),
});

const ContactForm = (props: IContactFormProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const initialValues: IContactFormValues = {
    name: '',
    email: '',
    message: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IContactFormValues>({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data: IContactFormValues) => {
    props.formSubmit(data);
    reset(initialValues);
  };
  const onErrors = (errors: any, e: any) => {
    console.log(errors, e);
  };
  return (
    <form className={`${ContactFormStyle.contact} ${props.darkMode ? ContactFormStyle['dark-mode'] : ''}`} onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
      <div className={ContactFormStyle['contact-container']}>
        <div className={ContactFormStyle['contact-inputs']}>
          <Controller
            name="name"
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }: any) => (
              <DynamicInput name="name" type="text" id="name" value={value} label={props.labels.name} error={errors.name?.message} onChange={(e: any) => onChange(e.target.value)} />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <DynamicInput name="email" type="text" id="email" value={value} label={props.labels.email} error={errors.email?.message} onChange={(e: any) => onChange(e.target.value)} />
            )}
          />
        </div>
        <div className={ContactFormStyle['contact-textarea']}>
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <DynamicTextarea
                name="message"
                type="text"
                id="message"
                styles={{ width: '100%' }}
                value={value}
                label={props.labels.message}
                error={errors.message?.message}
                onChange={(e: any) => onChange(e.target.value)}
              />
            )}
          />
        </div>
        <DynamicButton type={'submit'} disabled={isButtonDisabled} darkMode={props.darkMode} color={'tertiary'}>
          {props.labels.submit}
        </DynamicButton>

        {(() => {
          switch (props.submitResultType) {
            case 'success':
              return (
                <>
                  <hr className="separator-thin" />
                  <Message type="success" iconPath={'/assets/images/icons/EmailIcon.svg'}>
                    <ReactMarkdown>{props.labels.submitSuccessMessage}</ReactMarkdown>
                  </Message>
                </>
              );
            case 'error':
              return (
                <>
                  <hr className="separator-thin" />
                  <Message type="danger" iconPath={'/assets/images/icons/AlertIcon.svg'}>
                    <ReactMarkdown>{props.labels.submitErrorMessage}</ReactMarkdown>
                  </Message>
                </>
              );
            default:
              return null;
          }
        })()}
      </div>
    </form>
  );
};

export default ContactForm;
